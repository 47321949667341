import React, { useEffect, useState } from 'react';
import { Badge, Button, Drawer, Tooltip, message } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { getComentarios } from '../../services/ComentariosService';
import Identificador from './Identificador';
import Formulario from './Formulario';
import Listado from './Listado';
import { useSelector } from 'react-redux';

export default function ComentariosTrabajador({ trabajador }) {
  const [open, setOpen] = useState(false);
  const [comentarios, setComentarios] = useState(null);
  const { enterprise } = useSelector((state) => state.enterprise);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    obtenerComentarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  async function obtenerComentarios() {
    setComentarios(null);
    try {
      const result = await getComentarios(
        enterprise.empresaId,
        trabajador?.correo || trabajador?.trabajadorId
      );
      setComentarios(result);
    } catch (error) {
      console.error(error);
      message.error('No se pudo obtener los comentarios');
    }
  }

  return (
    <>
      <Tooltip title="Ver comentarios">
        <Badge count={comentarios?.length || 0} size="small" offset={[-5, 5]}>
          <Button type="text" icon={<MessageOutlined />} onClick={showDrawer} />
        </Badge>
      </Tooltip>
      <Drawer
        title={`Comentarios sobre el trabajador`}
        onClose={onClose}
        open={open}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <Identificador trabajador={trabajador} />
            <Listado comentarios={comentarios} />
          </div>
          <Formulario trabajador={trabajador} refresh={obtenerComentarios} />
        </div>
      </Drawer>
    </>
  );
}
