import { Button, Divider, Form, Input, message } from 'antd';
import { useState } from 'react';
import { createComentario } from '../../services/ComentariosService';
import { useSelector } from 'react-redux';

export default function Formulario({ trabajador, refresh }) {
  const [enviando, setEnviando] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);

  const [form] = Form.useForm();

  async function submitForm(values) {
    try {
      setEnviando(true);
      const body = {
        empresaId: enterprise.empresaId,
        trabajadorId: trabajador.correo || trabajador.trabajadorId,
        comentario: values.comentario,
        usuarioEmpresa: user.correo,
        feel: '',
      };
      await createComentario(body);
      message.success('Comentario enviado');
      refresh();
      form.resetFields();
      setEnviando(false);
    } catch (error) {
      console.error(error);
      message.error('No se pudo enviar el comentario');
      setEnviando(false);
    }
  }

  return (
    <div id="formularioComentarios">
      <Divider />
      <Form form={form} onFinish={submitForm}>
        <Form.Item
          name="comentario"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un comentario',
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={100}
            placeholder="Deja tu comentario..."
            style={{
              height: 80,
              resize: 'none',
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          className="mt-4"
          htmlType="submit"
          loading={enviando}
        >
          Enviar
        </Button>
      </Form>
    </div>
  );
}
