import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Space,
  DatePicker,
  InputNumber,
  TimePicker,
  message,
  Select,
  Badge,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { massiveGeneration } from '../../../../services/RequisitionMasiveService';
import { useSelector } from 'react-redux';
import { getDisabledDate } from '../../../../utils/getTurnTimes';
import { useForm } from 'antd/es/form/Form';

export default function FormMasivo({ solicitud, dataSolicitud, etiquetas }) {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const { enterprise } = useSelector((state) => state.enterprise);
  const { user } = useSelector((state) => state.auth);
  async function onFinish(values) {
    if (!solicitud) return;
    if (values.solicitudes.length < 1) return;
    try {
      setLoading(true);
      await massiveGeneration({
        baseId: solicitud,
        enterprise,
        arrSolicitudes: values.solicitudes,
        plantillaBase: dataSolicitud,
      });
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error(error.message || 'Error al generar las solicitudes');
    } finally {
      setLoading(false);
    }
  }

  const stylesItems = {
    width: '100%',
    maxWidth: 200,
  };

  const diasMaximos =
    enterprise.configuracionEmpresa?.oferta?.duracionMaxima ?? 1;

  // ContratoPlazoFijo y ContratoObraFaena
  const isPlantilla = !!dataSolicitud?.nombrePlantilla ?? false;

  return (
    <Form
      name="dynamic_form_nest_item"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.List name="solicitudes">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                }}
                align="baseline"
              >
                <span className="font-bold text">#{key + 1}</span>
                <Form.Item
                  {...restField}
                  label="Motivo de Contrato"
                  name={[name, 'motivo']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el motivo del contrato',
                    },
                  ]}
                >
                  <Input style={stylesItems} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Fecha de inicio"
                  name={[name, 'fecha']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor selecciona una fecha',
                    },
                  ]}
                >
                  <DatePicker
                    style={stylesItems}
                    disabledDate={!user.esMantenedor && getDisabledDate}
                    placeholder="DD/MM/YYYY"
                    format={'DD/MM/YYYY'}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Duración"
                  name={[name, 'duracion']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa la duración',
                    },
                  ]}
                  tooltip="Número de días que durará la solicitud, los días se cuentan a partir de la fecha de inicio y son consecutivos."
                >
                  <Select style={stylesItems} placeholder="1 dia">
                    {
                      // eslint-disable-next-line no-plusplus
                      [...Array(diasMaximos).keys()].map((i) => (
                        <Select.Option key={i + 1} value={i + 1}>
                          {i + 1} {i + 1 === 1 ? 'día' : 'días'}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Entrada"
                  name={[name, 'entrada']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor selecciona una hora',
                    },
                  ]}
                >
                  <TimePicker
                    format={'HH:mm'}
                    placeholder="00:00"
                    minuteStep={15}
                    showNow={false}
                    changeOnBlur
                    style={stylesItems}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Salida"
                  name={[name, 'salida']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor selecciona una hora',
                    },
                  ]}
                >
                  <TimePicker
                    format={'HH:mm'}
                    placeholder="23:59"
                    minuteStep={15}
                    showNow={false}
                    changeOnBlur
                    style={stylesItems}
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Vacantes"
                  name={[name, 'vacantes']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el número',
                    },
                    {
                      type: 'number',
                      min: 1,
                      message: 'Debe ser mayor a 0',
                    },
                  ]}
                >
                  <InputNumber placeholder="#" style={stylesItems} />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Liq. Aprox"
                  name={[name, 'pago']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el pago',
                    },
                  ]}
                  initialValue={
                    isPlantilla ? dataSolicitud?.turnos[0]?.valorUnitario : 0
                  }
                >
                  <InputNumber
                    style={{
                      width: '100%',
                      maxWidth: 150,
                    }}
                    placeholder="Ingresa pago"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    }
                    parser={(value) => value.replace(/\D/g, '')}
                    min={0}
                    controls={false}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Bono"
                  name={[name, 'bono']}
                  initialValue={
                    isPlantilla ? dataSolicitud?.turnos[0]?.bono : 0
                  }
                >
                  <InputNumber
                    style={{
                      width: '100%',
                      maxWidth: 120,
                    }}
                    placeholder="Ingresa pago"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    }
                    parser={(value) => value.replace(/\D/g, '')}
                    min={0}
                    controls={false}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Grupo de notificaciones"
                  name={[name, 'etiquetaId']}
                >
                  <Select
                    placeholder="Selecciona una etiqueta"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.key.toLowerCase().includes(input.toLowerCase())
                    }
                    disabled={
                      !etiquetas ||
                      etiquetas.length < 1 ||
                      dataSolicitud.tipoContrato !== 'ContratoObraFaena'
                    }
                  >
                    {etiquetas?.map(
                      ({ etiquetaId, nombre, color, totalTrabajadores }) => (
                        <Select.Option key={nombre} value={etiquetaId}>
                          <Badge
                            color={color}
                            text={`${nombre} (${totalTrabajadores})`}
                          />
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Nueva solicitud
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!solicitud}
          loading={loading}
        >
          Generar solicitudes
        </Button>
      </Form.Item>
    </Form>
  );
}
